/**
 * @generated SignedSource<<7229bc0e13e15640acd575c48519537d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientUIFeatureFlagsFragment_query$data = {
  readonly company: {
    readonly __typename: "Company";
    readonly AEH_ENABLE_SHOPIFY_WEB_PIXEL_SWP_ENDPOINT: boolean | null;
    readonly ALLOW_BIG_COMMERCE_PRODUCT_SYNC: boolean | null;
    readonly BFCM_DATE_RANGE_LOAD_SWITCH_LAST_SEVEN_DAYS: boolean | null;
    readonly CONCIERGE_PROFILE_CHECKOUT_STARTED: boolean | null;
    readonly CONCIERGE_PROFILE_ORDER_HISTORY: boolean | null;
    readonly CONVERSATIONS: boolean | null;
    readonly CREATIVES_UNLOCK_GEOFILTER: boolean | null;
    readonly DAMPEN_CREATIVE_ADA_VALIDATION: boolean | null;
    readonly DATADOG_JAVASCRIPT_SAMPLING_RATE: boolean | null;
    readonly DISABLE_AI_SEG_MEMBERSHIP_MACRO_ON_UTM_CAMPAIGN: boolean | null;
    readonly DISABLE_AUDIENCES_AI_BY_DEFAULT: boolean | null;
    readonly DISABLE_BACKGROUND_IFRAME_SRC_CREATIVE_PREVIEW: boolean | null;
    readonly DISABLE_CONCIERGE_USER_PROFILE: boolean | null;
    readonly DISABLE_CREATE_CAMPAIGN_FROM_ROUTE: boolean | null;
    readonly DISABLE_DEVICE_BASED_EVENT_MATCHING: boolean | null;
    readonly DISABLE_EMAIL_SIZE_VALIDATION: boolean | null;
    readonly DISABLE_HOLIDAY_SUBSCRIBER_SEGMENT_CSM_REC: boolean | null;
    readonly DISABLE_JOURNEYS_PDLI_ANNOUNCEMENTS: boolean | null;
    readonly DISABLE_JOURNEYS_PRICE_DROP_CSM_REC: boolean | null;
    readonly DISABLE_MAGIC_AUDIENCE_EXPANSION_SIZE_VALIDATION: boolean | null;
    readonly DISABLE_MESSAGE_EXPLORER_UNIQUE_PHONE_NUMBER_VALIDATION: boolean | null;
    readonly DISABLE_MESSAGE_VALIDATION: boolean | null;
    readonly DISABLE_REPORTING_AI_PRO_BUNDLE: boolean | null;
    readonly ENABLE_AB_CREATIVE_SERVICE: boolean | null;
    readonly ENABLE_AC_NUDGE_MODALS: boolean | null;
    readonly ENABLE_ADD_TO_CART_RULE: boolean | null;
    readonly ENABLE_ADMIN_CONCIERGE_AGENTS: boolean | null;
    readonly ENABLE_ADMIN_SETTINGS_EDIT_COMPANY_URLS: boolean | null;
    readonly ENABLE_ADVANCED_ANALYTICS_OPTIONS: boolean | null;
    readonly ENABLE_AGE_GATING: boolean | null;
    readonly ENABLE_AI_BRAND_VOICE: boolean | null;
    readonly ENABLE_AI_BRAND_VOICE_BRAND_KIT_UI: boolean | null;
    readonly ENABLE_AI_COPY_ASSISTANT_HOME_PAGE: boolean | null;
    readonly ENABLE_AI_COPY_WRITER: boolean | null;
    readonly ENABLE_AI_CREATIVES: boolean | null;
    readonly ENABLE_AI_JOURNEYS_PERFORMANCE_ATTRIBUTION: boolean | null;
    readonly ENABLE_AI_JOURNEYS_PERFORMANCE_KEY_METRICS: boolean | null;
    readonly ENABLE_AI_JOURNEYS_PERFORMANCE_NORMALIZED_LIFT: boolean | null;
    readonly ENABLE_AI_JOURNEYS_PERFORMANCE_REPORT: boolean | null;
    readonly ENABLE_AI_JOURNEYS_PERFORMANCE_TRAFFIC_SPLIT: boolean | null;
    readonly ENABLE_AI_JOURNEYS_PERFORMANCE_UI: boolean | null;
    readonly ENABLE_AI_JOURNEYS_UI: boolean | null;
    readonly ENABLE_AI_PRO_AGGREGATE_REPORTING: boolean | null;
    readonly ENABLE_AI_PRO_BRAND_VOICE_DEV: boolean | null;
    readonly ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE: boolean | null;
    readonly ENABLE_AI_PRO_CLIENT_FACING_EXPERIENCE_BRAND_VOICE: boolean | null;
    readonly ENABLE_AI_PRO_DASHBOARD_FF_STATUS_BANNER: boolean | null;
    readonly ENABLE_AI_PRO_DASHBOARD_MAXIMIZE_PERFORMANCE_LINK: boolean | null;
    readonly ENABLE_AI_PRO_SIXTY_TRIAL_SIGNUP: boolean | null;
    readonly ENABLE_AI_PRO_TOOLS: boolean | null;
    readonly ENABLE_AI_PRO_TRIAL_ENABLEMENT_V_THREE: boolean | null;
    readonly ENABLE_AI_PRO_TRIAL_SIGNUP_DELAY_MESSAGE: boolean | null;
    readonly ENABLE_ANALYTICS_DASHBOARD_FF_STATUS_BANNER: boolean | null;
    readonly ENABLE_ANALYTICS_DASHBOARD_RANGE_COMPARISON_SELECTOR: boolean | null;
    readonly ENABLE_ANALYTICS_FF_STATUS_BANNER: boolean | null;
    readonly ENABLE_ANALYTICS_UI_DEGRADED_BANNER: boolean | null;
    readonly ENABLE_API_CREATIVE_UI_SIGNUP: boolean | null;
    readonly ENABLE_APP_HEALTH_TAB: boolean | null;
    readonly ENABLE_APP_JOURNEY_CREATION: boolean | null;
    readonly ENABLE_APP_JOURNEY_SETUP: boolean | null;
    readonly ENABLE_APP_LISTING_CONTACT_FORM: boolean | null;
    readonly ENABLE_APP_PARTNERSHIP_TIERS: boolean | null;
    readonly ENABLE_APP_PERF_REQUEST_TAB: boolean | null;
    readonly ENABLE_ARCHIVING_CREATIVES: boolean | null;
    readonly ENABLE_ASSET_LIBRARY: boolean | null;
    readonly ENABLE_ASSET_LIBRARY_MOVE_ASSET: boolean | null;
    readonly ENABLE_ATT_STOP_LANGUAGE: boolean | null;
    readonly ENABLE_ATT_STOP_LANGUAGE_FE: boolean | null;
    readonly ENABLE_AUDIENCES_AI_CAMPAIGNS_DEBUG_MODE: boolean | null;
    readonly ENABLE_AUDIENCES_AI_CAMPAIGNS_PERFORMANCE: boolean | null;
    readonly ENABLE_AUDIENCES_AI_EMAIL_BETA: boolean | null;
    readonly ENABLE_AUTOMATED_MESSAGING: boolean | null;
    readonly ENABLE_AUTOMATED_MESSAGING_POC: boolean | null;
    readonly ENABLE_BACK_IN_STOCK_TEMPLATES: boolean | null;
    readonly ENABLE_BAZAARVOICE: boolean | null;
    readonly ENABLE_BEE_DOWNTIME_BANNER: boolean | null;
    readonly ENABLE_BEE_FONT_URL_UPDATING: boolean | null;
    readonly ENABLE_BFCM_HOLIDAY_CREATIVE_TEMPLATE: boolean | null;
    readonly ENABLE_BLOTOUT_WHITELABEL: boolean | null;
    readonly ENABLE_BRAND_KIT: boolean | null;
    readonly ENABLE_BRAND_VOICE_SETTINGS_TONE_KEYWORDS: boolean | null;
    readonly ENABLE_BV_AI_PRO_ASSISTANTS_EXPERIMENT: boolean | null;
    readonly ENABLE_CAMPAIGNS_COPY_MESSAGE_ID: boolean | null;
    readonly ENABLE_CAMPAIGNS_DATEPICKER_START_OF_WEEK_SUNDAY: boolean | null;
    readonly ENABLE_CAMPAIGNS_ESTIMATED_RECIPIENTS_GQL_REFACTOR: boolean | null;
    readonly ENABLE_CAMPAIGNS_LIST_PAGE_FILTERING_BY_TAGS: boolean | null;
    readonly ENABLE_CAMPAIGNS_PAGE_STATUS_BANNER: boolean | null;
    readonly ENABLE_CAMPAIGN_AUDIENCE_FORM_CREATE_SEGMENT: boolean | null;
    readonly ENABLE_CAMPAIGN_EMAIL: boolean | null;
    readonly ENABLE_CAMPAIGN_RETARGETING_EXPERIMENT: boolean | null;
    readonly ENABLE_CA_SHUT_OFF_SWITCH: boolean | null;
    readonly ENABLE_CDS_SCHEDULING: boolean | null;
    readonly ENABLE_CHANGE_DEFAULT_REGION: boolean | null;
    readonly ENABLE_CLICK_TO_EDIT_CREATIVES: boolean | null;
    readonly ENABLE_CLIENT_FACING_AI_JOURNEYS_REPORT: boolean | null;
    readonly ENABLE_CLIENT_USES_COOKIE_FILTERING: boolean | null;
    readonly ENABLE_CLOUDINARY_MMS_CREATION: boolean | null;
    readonly ENABLE_CMG_DETAIL_PAGE_AI_PRO_WARNING_BANNER: boolean | null;
    readonly ENABLE_COGNITO_SFTP_LOGIN: boolean | null;
    readonly ENABLE_COMPANY_INFO_BLOCKS_SETTINGS_UI: boolean | null;
    readonly ENABLE_CONCIERGE_ADMIN_SETTINGS: boolean | null;
    readonly ENABLE_CONCIERGE_AGENT_CHECKOUT_LINKS: boolean | null;
    readonly ENABLE_CONCIERGE_AGENT_CHECKOUT_LINKS_BETA: boolean | null;
    readonly ENABLE_CONCIERGE_AGENT_TEMPLATE_ENHANCED_SHORTCUTS: boolean | null;
    readonly ENABLE_CONCIERGE_ANALYTICS: boolean | null;
    readonly ENABLE_CONCIERGE_BRAND_VOICE: boolean | null;
    readonly ENABLE_CONCIERGE_CHAT_CONCURRENCY: boolean | null;
    readonly ENABLE_CONCIERGE_CHAT_CONCURRENCY_REDIRECT: boolean | null;
    readonly ENABLE_CONCIERGE_COMPANY_PROMOTIONS: boolean | null;
    readonly ENABLE_CONCIERGE_CONVERSATION_STATUS_BANNER_ESCALATION_NUDGE: boolean | null;
    readonly ENABLE_CONCIERGE_DETAILED_ORDER_HISTORY: boolean | null;
    readonly ENABLE_CONCIERGE_ESCALATIONS_TABLE: boolean | null;
    readonly ENABLE_CONCIERGE_ESCALATIONS_TABLE_SORT: boolean | null;
    readonly ENABLE_CONCIERGE_FULL_TEMPLATE_VIEW: boolean | null;
    readonly ENABLE_CONCIERGE_HOME_PAGE: boolean | null;
    readonly ENABLE_CONCIERGE_INFINITE_SCROLL: boolean | null;
    readonly ENABLE_CONCIERGE_INSIGHTS: boolean | null;
    readonly ENABLE_CONCIERGE_MACRO_LINK: boolean | null;
    readonly ENABLE_CONCIERGE_ML_RECOMMENDED_TEMPLATES: boolean | null;
    readonly ENABLE_CONCIERGE_ONBOARDING: boolean | null;
    readonly ENABLE_CONCIERGE_PRODUCT_RECS: boolean | null;
    readonly ENABLE_CONCIERGE_PRODUCT_RECS_AB: boolean | null;
    readonly ENABLE_CONCIERGE_SELF_SERVE_LAUNCH: boolean | null;
    readonly ENABLE_CONCIERGE_SPECIALIZATIONS_TAB: boolean | null;
    readonly ENABLE_CONCIERGE_STREAMLINE_USER_PROFILE: boolean | null;
    readonly ENABLE_CONCIERGE_TEMPLATES_ROUTE: boolean | null;
    readonly ENABLE_CONCIERGE_TRY_ADDING_A_QUESTION_BANNER: boolean | null;
    readonly ENABLE_CONVERSATIONS_GRAPHQL: boolean | null;
    readonly ENABLE_CONVERSATIONS_NEW_PREVIEWS: boolean | null;
    readonly ENABLE_CONVERSATIONS_SUBNAV: boolean | null;
    readonly ENABLE_CONVERSATION_MANAGER: boolean | null;
    readonly ENABLE_CONVO_BRAND_VOICE: boolean | null;
    readonly ENABLE_COUNTDOWN_TIMER_CREATIVES: boolean | null;
    readonly ENABLE_COUPON_SET_DISTRIBUTION_UI: boolean | null;
    readonly ENABLE_CREATE_SIGNUPS: boolean | null;
    readonly ENABLE_CREATIVES_DATALAYER_PUSH: boolean | null;
    readonly ENABLE_CREATIVES_EXPERIMENT_GQL: boolean | null;
    readonly ENABLE_CREATIVES_GOOGLE_ANALYTICS_FOUR: boolean | null;
    readonly ENABLE_CREATIVES_NORTHBEAM_PIXEL: boolean | null;
    readonly ENABLE_CREATIVE_AB_TEST_MANAGEMENT: boolean | null;
    readonly ENABLE_CREATIVE_CONSOLIDATION: boolean | null;
    readonly ENABLE_CREATIVE_COOKIE_TARGETING: boolean | null;
    readonly ENABLE_CREATIVE_OVERVIEW_PAGE: boolean | null;
    readonly ENABLE_CREATIVE_REFERRER_FILTERING: boolean | null;
    readonly ENABLE_CREATIVE_SCHEDULING: boolean | null;
    readonly ENABLE_CREATIVE_SOCIAL_PILL: boolean | null;
    readonly ENABLE_CREATIVE_SPECIFIC_ALREADY_SUB_MESSAGE: boolean | null;
    readonly ENABLE_CREATIVE_UTM_FILTERING: boolean | null;
    readonly ENABLE_CUSTOM_ATTRIBUTE_DATE_AND_NUMBER_TYPE_CREATION: boolean | null;
    readonly ENABLE_CUSTOM_ATTRIBUTE_IN_THE_NEXT_COMPARATOR: boolean | null;
    readonly ENABLE_CUSTOM_TAG_ENRICHMENTS_SETUP: boolean | null;
    readonly ENABLE_CUSTOM_TAG_EVENTS_SETUP: boolean | null;
    readonly ENABLE_DATADOG_SESSION_REPLAY: boolean | null;
    readonly ENABLE_DATE_PREF_COLLECTION: boolean | null;
    readonly ENABLE_DEMO_STATISTICS: boolean | null;
    readonly ENABLE_DISMISS_OPTIONAL_LINK: boolean | null;
    readonly ENABLE_DISPLAY_REQUIRE_TRACKING_CONSENT: boolean | null;
    readonly ENABLE_DISPLAY_RULES_CLIENT_FACING: boolean | null;
    readonly ENABLE_DISPLAY_RULES_ONBOARDING: boolean | null;
    readonly ENABLE_DISPLAY_RULES_SUPER_USER: boolean | null;
    readonly ENABLE_EDIT_COMPANY_TYPE: boolean | null;
    readonly ENABLE_EDIT_PAUSED_CAMPAIGNS: boolean | null;
    readonly ENABLE_EDIT_PAUSED_EXPERIMENTS: boolean | null;
    readonly ENABLE_EDIT_SIGNUPS: boolean | null;
    readonly ENABLE_EDS_PRODUCT_CATALOG: boolean | null;
    readonly ENABLE_EDS_UI: boolean | null;
    readonly ENABLE_EMAIL_AUDIENCE_AI_ALPHA: boolean | null;
    readonly ENABLE_EMAIL_COPY_RECIPIENTS_CAMPAIGNS: boolean | null;
    readonly ENABLE_EMAIL_COPY_RECIPIENTS_JOURNEYS: boolean | null;
    readonly ENABLE_EMAIL_HEATMAPS: boolean | null;
    readonly ENABLE_EMAIL_LINK_SUBDOMAIN: boolean | null;
    readonly ENABLE_EMAIL_LINK_SUBDOMAIN_UI: boolean | null;
    readonly ENABLE_EMAIL_LIST_CLEANING: boolean | null;
    readonly ENABLE_EMAIL_ONLY_CREATIVES: boolean | null;
    readonly ENABLE_EMAIL_ONLY_CREATIVES_REGION_OVERRIDE: boolean | null;
    readonly ENABLE_EMAIL_ON_ANALYTICS_DASHBOARD: boolean | null;
    readonly ENABLE_EMAIL_PREVIEW_SIZE_PROGRESS_BAR: boolean | null;
    readonly ENABLE_EMAIL_PREVIEW_SUBSCRIBER_SEARCH: boolean | null;
    readonly ENABLE_EMAIL_PRODUCT_RECOMMENDATIONS_PRODUCT_FEED_SELECTION: boolean | null;
    readonly ENABLE_EMAIL_SUBDOMAINS: boolean | null;
    readonly ENABLE_EMAIL_SUBJECT_LINE_EXPERIMENTS: boolean | null;
    readonly ENABLE_EMAIL_SYNCED_ROWS: boolean | null;
    readonly ENABLE_EMAIL_TRIGGER_INTEGRATION: boolean | null;
    readonly ENABLE_EMAIL_UNSUBSCRIBE: boolean | null;
    readonly ENABLE_ENTRY_FREQUENCY_EDITING_FOR_TRANSACTIONAL_JOURNEYS: boolean | null;
    readonly ENABLE_ERROR_CODE_FILTERING: boolean | null;
    readonly ENABLE_EU_PREPEND_COUNTRY_CODE: boolean | null;
    readonly ENABLE_FLORIDA_SUBSCRIBERS_AUDIT: boolean | null;
    readonly ENABLE_FULL_WIDTH_BUBBLE: boolean | null;
    readonly ENABLE_GENERATED_REPORTS_PAGE_FF_STATUS_BANNER: boolean | null;
    readonly ENABLE_GENERATED_REPORTS_RECIPIENT_SELECT: boolean | null;
    readonly ENABLE_GENERATIVE_EMAIL_ROW_POC: boolean | null;
    readonly ENABLE_GEN_AI_TEXT_EXPERIMENT: boolean | null;
    readonly ENABLE_GIF_BACKGROUND_EXPERIMENT: boolean | null;
    readonly ENABLE_GIF_CREATOR: boolean | null;
    readonly ENABLE_GLOBAL_NOTIFICATIONS_UI: boolean | null;
    readonly ENABLE_GMAIL_PROMO_OFFER_DETAILS: boolean | null;
    readonly ENABLE_GORGIAS_OAUTH: boolean | null;
    readonly ENABLE_GRAPHQL_MESSAGE_EXPLORER: boolean | null;
    readonly ENABLE_GRAPH_GURU: boolean | null;
    readonly ENABLE_HELP_LIVE_CHAT_CONSOLIDATION: boolean | null;
    readonly ENABLE_HIDING_SUMMARY_METRICS: boolean | null;
    readonly ENABLE_HOME_UI_NOTIFICATION_SUMMARY: boolean | null;
    readonly ENABLE_HUBSPOT_OAUTH: boolean | null;
    readonly ENABLE_INDIVIDUAL_LINK_PARAMS: boolean | null;
    readonly ENABLE_INTEGRATION_ERRORS: boolean | null;
    readonly ENABLE_INTL_BULK_SUBSCRIBER_UPLOAD: boolean | null;
    readonly ENABLE_JOURNEYS_CARRIER_RESTRICTIONS_VALIDATION: boolean | null;
    readonly ENABLE_JOURNEYS_CONCIERGE: boolean | null;
    readonly ENABLE_JOURNEYS_CONCIERGE_CONTROLS: boolean | null;
    readonly ENABLE_JOURNEYS_GQL_JOURNEY_TRIGGERS_DATA: boolean | null;
    readonly ENABLE_JOURNEYS_JOINED_A_SEGMENT: boolean | null;
    readonly ENABLE_JOURNEYS_LIST_TABLE_UI: boolean | null;
    readonly ENABLE_JOURNEYS_LOW_INVENTORY: boolean | null;
    readonly ENABLE_JOURNEYS_MAP_HEADER_GQL: boolean | null;
    readonly ENABLE_JOURNEYS_PICK_A_WINNER: boolean | null;
    readonly ENABLE_JOURNEYS_PRICE_DROP: boolean | null;
    readonly ENABLE_JOURNEYS_RESUBSCRIBE_WELCOME_MESSAGE: boolean | null;
    readonly ENABLE_JOURNEYS_SAVE_USER_ATTRIBUTE: boolean | null;
    readonly ENABLE_JOURNEYS_SKIP_FETCHING_STATS_FOR_LIST_PAGE: boolean | null;
    readonly ENABLE_JOURNEYS_VERSION_MANAGEMENT: boolean | null;
    readonly ENABLE_JOURNEY_BUILDER_FORMS_GQL: boolean | null;
    readonly ENABLE_JOURNEY_EMAIL_NODE_SAVE_INVALID_DOMAIN: boolean | null;
    readonly ENABLE_JOURNEY_PREVIEW: boolean | null;
    readonly ENABLE_JOURNEY_SCHEDULER: boolean | null;
    readonly ENABLE_KEYWORDS_UI: boolean | null;
    readonly ENABLE_KLAVIYO_OPT_IN_SYNC: boolean | null;
    readonly ENABLE_LEGACY_SUBSCRIBER_REPORTS: boolean | null;
    readonly ENABLE_LIMIT_IMAGE_UPLOAD_SIZE_IN_CAMPAIGNS: boolean | null;
    readonly ENABLE_LIQUID_CONDITIONAL_DEFAULTING: boolean | null;
    readonly ENABLE_LIQUID_DISPLAY_CONDITIONS: boolean | null;
    readonly ENABLE_LITIGATOR_OPT_OUT: boolean | null;
    readonly ENABLE_MAGIC_AUDIENCES_MVP: boolean | null;
    readonly ENABLE_MAGIC_AUDIENCES_MVP_CLIENT_CTA: boolean | null;
    readonly ENABLE_MAGIC_AUDIENCES_MVP_CLIENT_TOGGLE: boolean | null;
    readonly ENABLE_MARKETPLACE_ADMIN_PAGE: boolean | null;
    readonly ENABLE_MAX_HEIGHT_DYNAMIC_IMAGES: boolean | null;
    readonly ENABLE_MESSAGES_SUPER_USER_AUTO_RESPONSE_FIELDS: boolean | null;
    readonly ENABLE_MESSAGE_CONTENT_MODERATION: boolean | null;
    readonly ENABLE_MESSAGE_EXPLORER_TOOL_UI: boolean | null;
    readonly ENABLE_MESSAGE_ID_IN_CAMPAIGN_REPORTS: boolean | null;
    readonly ENABLE_METRICS_API_POINT_IN_TIME_METRICS: boolean | null;
    readonly ENABLE_MOBILE_APP_SIGNUPS: boolean | null;
    readonly ENABLE_MPARTICLE_ATTRIBUTES: boolean | null;
    readonly ENABLE_MULTIPLE_CUSTOM_ATTRIBUTES: boolean | null;
    readonly ENABLE_MULTI_REGION_OPT_OUT_LANGUAGE: boolean | null;
    readonly ENABLE_MYSTERY_OFFER_CREATIVE: boolean | null;
    readonly ENABLE_NEW_ADMIN_SETTINGS: boolean | null;
    readonly ENABLE_NEW_AFTERSHIP_SETUP: boolean | null;
    readonly ENABLE_NEW_CB_THREE_FEATURES: boolean | null;
    readonly ENABLE_NEW_CREATIVES_ATTRIBUTES_UI: boolean | null;
    readonly ENABLE_NEW_HOME_PAGE_DESIGN: boolean | null;
    readonly ENABLE_NINE_REGION_EXPANSION: boolean | null;
    readonly ENABLE_NOTIFICATION_CONTENT_MANAGEMENT: boolean | null;
    readonly ENABLE_NO_AUTH_CORS_MERGED_CONFIGS: boolean | null;
    readonly ENABLE_OFFER_DISPLAY_CREATIVES: boolean | null;
    readonly ENABLE_OFFER_VALUE_INPUT: boolean | null;
    readonly ENABLE_OLO_SYNC_PRODUCTS: boolean | null;
    readonly ENABLE_ONBOARDING_FLOW_UI: boolean | null;
    readonly ENABLE_ONE_DAY_MESSAGE_HISTORY_CONV_UI: boolean | null;
    readonly ENABLE_OPEN_IN_BROWSER_SHORT_LINKS: boolean | null;
    readonly ENABLE_OVERRIDE_OFFER_QUERY_PARAMETERS: boolean | null;
    readonly ENABLE_PAST_PURCHASER_RULE: boolean | null;
    readonly ENABLE_PDP_SOCIAL_PILL: boolean | null;
    readonly ENABLE_PERIOD_OVER_PERIOD_REPORTING: boolean | null;
    readonly ENABLE_PERIOD_OVER_PERIOD_REPORTING_MILESTONE_TWO: boolean | null;
    readonly ENABLE_PRE_ENGAGEMENT_CREATIVES: boolean | null;
    readonly ENABLE_PRIVACY_EMAIL_ATTRIBUTION_CONTROL: boolean | null;
    readonly ENABLE_PRODUCT_CATALOG_UI: boolean | null;
    readonly ENABLE_PRODUCT_DATA: boolean | null;
    readonly ENABLE_PRODUCT_DATA_DIALOG_SELECT_ALL_UI: boolean | null;
    readonly ENABLE_PRODUCT_DISPLAY_CREATIVES: boolean | null;
    readonly ENABLE_PRODUCT_FEEDS_INVENTORY_FILTER: boolean | null;
    readonly ENABLE_PRODUCT_FEEDS_PRICE_FILTER: boolean | null;
    readonly ENABLE_PRODUCT_FEEDS_TAG_FILTER: boolean | null;
    readonly ENABLE_PRODUCT_FEEDS_UI: boolean | null;
    readonly ENABLE_PRODUCT_HIGHLIGHT_CREATIVES: boolean | null;
    readonly ENABLE_PROGRAM_PERFORMANCE_REPORT: boolean | null;
    readonly ENABLE_PUBLIC_APP_PUBLISH_APP_FLOW: boolean | null;
    readonly ENABLE_PUBLISH_APP_RICH_TEXT_EDITOR: boolean | null;
    readonly ENABLE_PUNCHH_ATTENTIVE_LOYALTY_SIGNUP: boolean | null;
    readonly ENABLE_PUNCHH_ATTRIBUTES: boolean | null;
    readonly ENABLE_RECHARGE_ATTRIBUTES: boolean | null;
    readonly ENABLE_RECHARGE_ORDER_EVENTS: boolean | null;
    readonly ENABLE_RECORDING_SENDGRID_EMAIL_ACTIVITY: boolean | null;
    readonly ENABLE_REGION_SETTINGS_CONTACTCARD: boolean | null;
    readonly ENABLE_RENDERER_DISPLAY_RULES: boolean | null;
    readonly ENABLE_REPORTING_AI_PRO_BUNDLE: boolean | null;
    readonly ENABLE_REPORTING_CUSTOM_CHARTS: boolean | null;
    readonly ENABLE_REPORTING_METRICS_TABLE_MIGRATION: boolean | null;
    readonly ENABLE_REPORTING_REGION_CURRENCY_OVERRIDE: boolean | null;
    readonly ENABLE_REPORT_PAGE_FF_STATUS_BANNER: boolean | null;
    readonly ENABLE_REPORT_PAGE_METRIC_TOOLTIPS: boolean | null;
    readonly ENABLE_REPORT_PAGE_STICKY_TABLE: boolean | null;
    readonly ENABLE_REPORT_TOUR_MENU_OPTION: boolean | null;
    readonly ENABLE_RESTRICT_INACTIVE_MESSAGING_SERVICE: boolean | null;
    readonly ENABLE_REVISED_SEGMENT_CREATION_FLOW: boolean | null;
    readonly ENABLE_RSP_PRODUCT_RECS_SUBSCRIBER_FILTER: boolean | null;
    readonly ENABLE_SALESFORCE_SERVICE_CLOUD_SANDBOX: boolean | null;
    readonly ENABLE_SCALE_AI_IMAGES: boolean | null;
    readonly ENABLE_SCHEDULES_REDESIGN: boolean | null;
    readonly ENABLE_SCHEDULES_REDESIGN_CALENDAR: boolean | null;
    readonly ENABLE_SCHEDULES_WORKFLOW: boolean | null;
    readonly ENABLE_SEGMENTATION_CUSTOM_EVENT_FILTERING: boolean | null;
    readonly ENABLE_SEGMENTATION_NEW_TIME_COMPARATORS: boolean | null;
    readonly ENABLE_SEGMENTATION_PARTIAL_DATES: boolean | null;
    readonly ENABLE_SEGMENTATION_SUBSCRIBERS_IN_SEGMENT: boolean | null;
    readonly ENABLE_SEGMENTATION_WELL_KNOWN_PROP_FILTER: boolean | null;
    readonly ENABLE_SEGMENT_ASSISTANT_EXPERIMENT: boolean | null;
    readonly ENABLE_SEGMENT_BUILDER_DEBUG: boolean | null;
    readonly ENABLE_SEGMENT_BUILDER_OPERATOR_UI: boolean | null;
    readonly ENABLE_SEGMENT_BUILDER_OPERATOR_UI_TOGGLE: boolean | null;
    readonly ENABLE_SEGMENT_BY_JOURNEY_MESSAGE: boolean | null;
    readonly ENABLE_SEGMENT_BY_KLAVIYO_EMAIL_EVENT: boolean | null;
    readonly ENABLE_SEGMENT_BY_KLAVIYO_PROFILE_ATTRIBUTES: boolean | null;
    readonly ENABLE_SEGMENT_BY_MESSAGE_WITH_KEYWORD: boolean | null;
    readonly ENABLE_SEGMENT_ENHANCEMENT_KILL_SWITCH: boolean | null;
    readonly ENABLE_SEGMENT_LOCATION_ATTRIBUTES_INTL: boolean | null;
    readonly ENABLE_SEGMENT_ON_LANGUAGE: boolean | null;
    readonly ENABLE_SEGMENT_ON_REGION: boolean | null;
    readonly ENABLE_SEGMENT_USERID_SELECT_FEATURE_FIELD: boolean | null;
    readonly ENABLE_SEND_TIME_OPTIMIZATION_EMAIL: boolean | null;
    readonly ENABLE_SEND_TIME_OPTIMIZATION_TEXT: boolean | null;
    readonly ENABLE_SERVER_SIDE_TAG_URL: boolean | null;
    readonly ENABLE_SERVICE_CLOUD_CUSTOM_ATTRIBUTE_FIELD_MAPPING: boolean | null;
    readonly ENABLE_SERVICE_CLOUD_CUSTOM_EXTERNAL_ID: boolean | null;
    readonly ENABLE_SERVICE_CLOUD_CUSTOM_FIELD_MAPPING: boolean | null;
    readonly ENABLE_SESSION_BV_PREVIEW: boolean | null;
    readonly ENABLE_SFMC_POSTBACK_ENHANCEMENTS: boolean | null;
    readonly ENABLE_SFTP_VISUAL_REPORT_DELIVERY: boolean | null;
    readonly ENABLE_SHARED_EMAIL_TEMPLATES: boolean | null;
    readonly ENABLE_SHARED_VS_DEDICATED_EMAIL_DOMAIN_TOGGLE: boolean | null;
    readonly ENABLE_SHOPIFY_AUTO_GEN_FOR_WINBACK_JOURNEY: boolean | null;
    readonly ENABLE_SHOW_AUTO_JOURNEY_STATS: boolean | null;
    readonly ENABLE_SIGNUP_UNIT_PREVIEW: boolean | null;
    readonly ENABLE_SLE_MILESTONE_TWO: boolean | null;
    readonly ENABLE_SMILE_ATTRIBUTES: boolean | null;
    readonly ENABLE_SMS_ATTACH_OFFER_FLOW: boolean | null;
    readonly ENABLE_SMS_MEDIA_DYNAMIC_IMAGE_MACRO_SELECTION: boolean | null;
    readonly ENABLE_SPECIFIC_GEO_TARGETING_CREATIVES: boolean | null;
    readonly ENABLE_SUBSCRIBERS_ARCHIVE_TABLE_GQL: boolean | null;
    readonly ENABLE_SUBSCRIBERS_UI_GRAPHQL: boolean | null;
    readonly ENABLE_SUBSCRIBERS_UI_GRAPHQL_METRICS: boolean | null;
    readonly ENABLE_SUBSCRIBER_AUDIT: boolean | null;
    readonly ENABLE_SUBSCRIBER_REPORTS_UPDATE: boolean | null;
    readonly ENABLE_SUBSCRIBER_UPLOAD: boolean | null;
    readonly ENABLE_SUBSCRIBER_UPLOAD_AUTOFIX_EMPTY_HEADERS: boolean | null;
    readonly ENABLE_SUBSCRIBER_UPLOAD_EXISTING_SUBSCRIBERS: boolean | null;
    readonly ENABLE_SUBSCRIBER_UPLOAD_IGNORE_COLUMN: boolean | null;
    readonly ENABLE_SUCCESS_PAGE_TIMEOUT_CONTROLS: boolean | null;
    readonly ENABLE_SUPER_USER_CAMPAIGN_SCHEDULING_WARNING: boolean | null;
    readonly ENABLE_SYNCED_ROWS_GA_ANNOUNCEMENT: boolean | null;
    readonly ENABLE_TEST_MAGIC_IMAGE_CTA: boolean | null;
    readonly ENABLE_TRANSACTIONAL_JOURNEY_APPROVAL_BYPASS: boolean | null;
    readonly ENABLE_TRANSACTIONAL_JOURNEY_SHOPIFY_PLUS_SEND_EMAIL_VALIDATION: boolean | null;
    readonly ENABLE_TRANSACTIONAL_PRODUCT_RECS: boolean | null;
    readonly ENABLE_TRIGGERED_EMAIL: boolean | null;
    readonly ENABLE_TTJ_CODE_MANAGEMENT: boolean | null;
    readonly ENABLE_TTJ_EDITING: boolean | null;
    readonly ENABLE_TWO_IN_ONE_DYNAMIC_CREATIVE: boolean | null;
    readonly ENABLE_UNIFIED_AUTO_JOURNEY: boolean | null;
    readonly ENABLE_UNIFIED_COMPANY_SETTINGS: boolean | null;
    readonly ENABLE_UPDATED_EMAIL_COMPOSER: boolean | null;
    readonly ENABLE_USE_DASHBOARD_TEXT_REVENUE_LABELS: boolean | null;
    readonly ENABLE_VIRTUAL_CONTACT_CARD: boolean | null;
    readonly ENABLE_WELCOME_JOURNEY_SIGNUP_PAUSING: boolean | null;
    readonly ENABLE_WELCOME_MESSAGE_MMS: boolean | null;
    readonly ENABLE_YOTPO_ATTRIBUTES: boolean | null;
    readonly ENABLE_ZENDESK_HELP_CENTER_SEARCH_BAR: boolean | null;
    readonly ENABLE_ZENDESK_OAUTH: boolean | null;
    readonly ENFORCE_AGE_GATING: boolean | null;
    readonly HIDE_ANALYTICS_BILLABLE_SPEND: boolean | null;
    readonly HIDE_ELIGIBLE_SUBSCRIPTION_COUNT_AGGREGATE_VALUE: boolean | null;
    readonly HIDE_TOTAL_ACTIVE_SUBSCRIBERS_AGGREGATE_VALUE: boolean | null;
    readonly JOURNEYS_CLOSE_PROXIMITY_WARNING_BANNER: boolean | null;
    readonly LEGACY_ADMIN_MIGRATION_URL_RULES: boolean | null;
    readonly ONBOARDING_PHASE_IV_BRAND_STYLES: boolean | null;
    readonly ONBOARDING_REGION_MGMT: boolean | null;
    readonly OVERRIDE_CREATIVE_ADA_VALIDATION: boolean | null;
    readonly OVERRIDE_CREATIVE_EDITOR_PERMISSIONS_LIVENATION: boolean | null;
    readonly OVERRIDE_RESTRICT_INACTIVE_MESSAGING_SERVICE: boolean | null;
    readonly OVERRIDE_TEMPLATE_ACCESS_AU_SPIN_TO_WIN: boolean | null;
    readonly OVERRIDE_TEMPLATE_ACCESS_CA_SPIN_TO_WIN: boolean | null;
    readonly OVERRIDE_TEMPLATE_ACCESS_DE_SPIN_TO_WIN: boolean | null;
    readonly OVERRIDE_TEMPLATE_ACCESS_GB_SPIN_TO_WIN: boolean | null;
    readonly OVERRIDE_TEMPLATE_ACCESS_PT_SPIN_TO_WIN: boolean | null;
    readonly OVERRIDE_TEXTED_A_KEYWORD_JOURNEYS_LIMIT: boolean | null;
    readonly READ_COMPANY_LINK_PARAMETERS_FROM_POSTGRES: boolean | null;
    readonly REFERENCE_UI_TEST: boolean | null;
    readonly RELATIVE_TIMEZONE_SENDING_FOR_ALL_REGIONS: boolean | null;
    readonly REQUIRE_WEBAUTHN_FOR_ADMIN_ACCESS: boolean | null;
    readonly RTL_EDITOR_AFFILIATE_ENDPOINT: boolean | null;
    readonly RTL_EDITOR_DYNAMIC: boolean | null;
    readonly RTL_EDITOR_PRIVACY: boolean | null;
    readonly RTL_EDITOR_RECHARGE_CHECKOUT: boolean | null;
    readonly RTL_EDITOR_RECHARGE_CHECKOUT_TRANSACTIONAL: boolean | null;
    readonly RTL_EDITOR_SHOPIFY_CHECKOUT: boolean | null;
    readonly RTL_EDITOR_SHOPIFY_CHECKOUT_EMAIL: boolean | null;
    readonly RTL_EDITOR_SHOPIFY_CHECKOUT_TRANSACTIONAL: boolean | null;
    readonly RTL_EDITOR_TERMS: boolean | null;
    readonly SELF_SERVE_ONBOARDING: boolean | null;
    readonly SEND_TEST_MESSAGES_ON_LONG_CODES: boolean | null;
    readonly SHOW_ACCOUNT_PROFILE_PAGE: boolean | null;
    readonly SHOW_PLATFORM_UNAVAILABLE_PAGE_CLIENT_UI: boolean | null;
    readonly SHOW_SAMPLE_UI_RED_HEADER: boolean | null;
    readonly SHOW_SLOW_LOAD_TIMES_DIALOG_CLIENT_UI: boolean | null;
    readonly SHOW_SYSTEM_STATUS_MESSAGE: boolean | null;
    readonly TAG_EDIT_REFACTOR: boolean | null;
    readonly UI_INTEGRATIONS: boolean | null;
    readonly UI_LOW_DATA_MODE: boolean | null;
    readonly UI_SHOW_REPORT_DELAY: boolean | null;
    readonly UNARCHIVE_CREATIVES: boolean | null;
    readonly USE_WITHIN_THE_LAST_DEFAULT_IN_SEGMENT_BUILDER: boolean | null;
    readonly ZENDESK_SIGN_IN_BEFORE_REDIRECT: boolean | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly " $fragmentType": "ClientUIFeatureFlagsFragment_query";
};
export type ClientUIFeatureFlagsFragment_query$key = {
  readonly " $data"?: ClientUIFeatureFlagsFragment_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClientUIFeatureFlagsFragment_query">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ClientUIFeatureFlagsFragment_query"
};

(node as any).hash = "a8f83ee96a6286d20ef182b598bb0ee8";

export default node;
